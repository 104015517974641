<template>
  <div class="section" style="background: rgba(0, 0, 0, 0.3); padding-bottom: 0px">
    <div class="container-fluid custom-container-fluid">
      <div class="row">
        <div class="col-md-3">
          <h3 class="text-atvyellow font-weight-bold">{{ about.company_name }}</h3>
          <p>
            {{ about.about_info }}
          </p>
        </div>
        <div class="col-md-3 offset-md-1">
          <h3 class="font-weight-bold">Contact Us</h3>
          <h4 class="text-atvyellow">
            <a href="mailto:info@Aukiss.tv">{{ about.contact_email }}</a>
            <!-- <br><a href="mailto:submissions@liferecordsent.com">submissions@liferecordsent.com</a> -->
          </h4>
          <!-- <h4><a href="tel:001243435xxxx">00 1243435 xxxx</a> / <a href="tel:001259826xxxx">00 1259826 xxxx</a></h4> -->
        </div>
        <div class="col-md-3 offset-md-1">
          <h3 class="font-weight-bold">Follow Us</h3>
          <a :href="about.facebook_page" target="_blank">
            <img
              src="../../public/assets/img/facebook.png"
              alt=""
              class="img-fluid mr-3"
            />
          </a>
          <a :href="about.twitter_page" target="_blank">
            <img
              src="../../public/assets/img/twitter.png"
              alt=""
              class="img-fluid mr-3"
            />
          </a>
          <a :href="about.instagram_page" target="_blank">
            <img
              src="../../public/assets/img/instagram.png"
              alt=""
              class="img-fluid mr-3"
            />
          </a>
          <a :href="about.youtube_page" target="_blank">
            <img
              src="../../public/assets/img/youtube.png"
              alt=""
              class="img-fluid mr-3"
            />
          </a>
        </div>
      </div>
    </div>
    <div class="mt-3" style="background: #000000">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <div class="p-4">&copy; Aukiss TV {{date.getFullYear()}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "footer_nav",
  data() {
    return {
      about: {},
      date: new Date(),
    };
  },
  methods: {
    getMyAbout() {
      axios
        .get("https://api.aukiss.tv/api/webaboutInfo")
        .then((res) => {
          this.about = res.data.data;
          // console.log("res about", this.about);
        })
        .finally(() => {});
    },
  },
  created() {
    this.getMyAbout();
  },
};
</script>
